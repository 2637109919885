import React, { useEffect, Component } from "react"
import { GoogleMap, useLoadScript, LoadScript, InfoWindow, Marker } from "@react-google-maps/api"
import { mapStyles, GOOGLE_MAP_API_KEY } from "./constants"
import HTMLReactParser from 'html-react-parser';
import _ from "lodash"
import marker_icon from "../images/map-marker.svg";

const GoogleSingleMap = (props) => {
  const containerStyle = {
    width: "100%",
    height: props.height,
  }

  const center = {
    lat: props.lat,
    lng: props.lng,
  }

  const position = {
    lat: props.lat,
    lng: props.lng,
  }

  const [map, setMap] = React.useState(null);
  const [streetView, setStreetView] = React.useState(props.streetView);

  const [showingInfoWindow, setShowingInfoWindow] = React.useState(false);
  const [activeMarker, setActiveMarker] = React.useState({});
  const [selectedPlace, setSelectedPlace] = React.useState({});
  const [currentInfo, setCurrentInfo] = React.useState('');


  useEffect(() => {
    setStreetView(props.streetView);
  }, [props.streetView]);


  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const onMarkerClick = (infoId, e) => {
    //setShowingInfoWindow(true);
    if (infoId === currentInfo) {
      setCurrentInfo('')
    } else {
      setCurrentInfo(infoId)
    }

  }
  console.log("google map api",process.env.GATSBY_MAPS_API_KEY);
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey:  process.env.GATSBY_MAPS_API_KEY
  })

  var className = "";
  if (!_.isEmpty(props.image)){
    className = "image";
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
        mapTypeControl: false,
        streetViewControl: false
      }}
    >
      <Marker position={position} icon={marker_icon} onClick={(e) => onMarkerClick('info-1', e)}>
        {
          currentInfo === "info-1" && props.OfficeName && (
            <InfoWindow
              marker={true}
              visible={true}
            >
              <div className={`marker-tooltip ${className}` }>
              {!_.isEmpty(props.image) &&
                  <>
                    <img loading="lazy" src={props.image[0]?.url} alt={props.OfficeName} width="150" height="170"/>
                  </>
                }
                <h2>{props.OfficeName}</h2>
                {props.OfficeAddress &&
                  <div className="marker-details">
                    <p><b>{HTMLReactParser(props.OfficeAddress.replace(/\n/g, '<br />'))}</b></p>
                  </div>

                }
               
              </div>
            </InfoWindow>
          )
        }
      </Marker>

    </GoogleMap>

  ) : <></>
}

export default React.memo(GoogleSingleMap)