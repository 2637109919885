import * as React from "react"
import { Link } from "@StarberryUtils";

import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import img01 from "../../../images/home/Properties/img01.png"
import tab01 from "../../../images/home/Properties/tab-img01.png"
import img02 from "../../../images/home/Properties/img02.png"
import desk01 from "../../../images/home/Properties/desk01.png"
import desk02 from "../../../images/home/Properties/desk02.png"
import desk03 from "../../../images/home/Properties/desk03.png"
import desk04 from "../../../images/home/Properties/desk04.png"
import labelIMG from "../../../images/label.png"
//import ScrollAnimation from "react-animate-on-scroll";
import Sale from './sales';
import Let from './lettings';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import _ from "lodash"
import Slider from "react-slick";
import { PhoneIconBlue, EnvelopeIconBlue, WhatsAppIconBlue, CloseIcon } from  "../../Icons/Icons";
import { useLocation } from "@reach/router"
import Form from "../../forms/book-a-viewing-form"
import ModalTiny from "react-bootstrap/Modal"
import "./Properties.scss"

const SimProperties = (props) => {
  let [isPlay, setPlay] = React.useState(false);
  const [key, setKey] = React.useState('sale');
  const [negotiator, setNegotiator] = React.useState(null);
  const [modalAskformOpen, setAskformOpen] = React.useState(false);

  const location = useLocation();

  var location_link = process.env.GATSBY_SITE_URL+location.pathname;
  var whatsapp_link = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	if(location.search.indexOf("utm_source") > 0){
		whatsapp_link = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	}
 
  const settings = {
    dots: true,
    lazyLoad: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  }
  const getPOA = (data, price) => {
    var permit = 'AED ' + price.toLocaleString();
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }
  const formatNumberWithComma = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const openAskformModal = () => {
    setAskformOpen(true);
  }
  const closeAskformModal = () => {
    setAskformOpen(false);
  }


  return (
      <section className="similar-properties-wrapper featured-properties-wrap sec-pdy-80">
      <Container className="featured-properties">
        <h5>Similar Properties in {props.place}</h5>
        <Row>
          <Col lg={12} className="property-details-similar ">

            <Slider  {...settings}>
              {props.data && props.data.map((item, index) => {
                // property details url structure
                let uriStr = "";

                if (item.search_type === "sales" && props.tag != "commercial") {
                  uriStr = `properties-for-sale/`
                } else if (item.search_type === "lettings") {
                  uriStr = `properties-for-rent/`
                }

                if (props.tag == "commercial") {
                  if (item.search_type === "sales") {
                    uriStr = `commercial-properties-for-sale/`
                  } else if (item.search_type === "lettings") {
                    uriStr = `commercial-properties-for-rent/`
                  }
                }

                if (props.tag == "new-homes") {
                  if (item.search_type === "sales") {
                    uriStr = `new-homes-for-sale/`
                  } else if (item.search_type === "lettings") {
                    uriStr = `new-homes-for-rent/`
                  }
                }
                // property details url structure 

                var image_url = img01;
                if (!_.isEmpty(item.images)) {
                  image_url = item.images[0].url
                }

                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.images_Transforms) {
                  processedImages = item.imagetransforms.images_Transforms;
                }
                if (!_.isEmpty(item.images)) {
                  return (

                    <div className="all-products img-zoom">
                        <div className="properties-img-wrapper ">

                        <Link to={'/' + uriStr + item.slug.replace(/_/g, '-') + '-' + item.id}>
                          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="property.images.similar_properties" attr={{ alt: item.address?.area + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={item._id} />
                          </Link>
                        </div>
                      <div className="about-properties">
                        <div className="properties-info">
                            <h2 className="text-md-bold-20-16">
                              <Link to={'/' + uriStr + item.slug.replace(/_/g, '-') + '-' + item.id}>
                                {item.title}
                              </Link>
                            </h2>
                            <div className="price text-md-bold-20-16">
                              {getPOA(item.extras, item.price)}
                            </div>  
                            <div className="about-location">
                              <span><i className="icon-location"></i></span>
                              <span className="location-info text-xxs">
                                {item?.address.Address && (item?.address.Address + ", ")}{item?.address.area && (item?.address.area + ", ")} {item?.address.town && (item?.address.town)}                          
                              </span>
                            </div>
                            {item.bedroom !== 0 || item.bathroom !== 0 ?
                              <div className="properties-icons">
                                {item.bedroom !== 0 &&
                                  <div className="text">
                                    <span><i className="icon-bedroom"></i></span>
                                    <span class="text-xxs-bold">
                                      {item.bedroom}
                                    </span>
                                  </div>
                                }
                                {item.bathroom !== 0 &&
                                  <div className="text">
                                    <span><i className="icon-bath">
                                    </i></span>
                                    <span class="text-xxs-bold">
                                      {item.bathroom}
                                    </span>
                                  </div>
                                }
                                {item.floor_area !== 0 &&
                                  <div className="text">
                                    <span><i className="icon-space">
                                    </i></span>
                                    <span class="text-xxs-bold">
                                      {formatNumberWithComma(item.floor_area)} sq.ft
                                    </span>
                                  </div>
                                }
                                </div> : <div className="properties-icons">&nbsp;</div>
                             }
                        </div>
                        <div className="agent-contact-info">
                                                <Link href={`tel:${negotiator?.Phone ? negotiator?.Phone : "+971 4 423 2006"}`} className="agent-number text-xxs btn btn-light-blue"><span><PhoneIconBlue className="icon-contact-info"/> </span><span>Call</span></Link>
                                                <a href="javascript:void(0)" onClick={openAskformModal} className="text-xxs btn btn-light-blue"><span><EnvelopeIconBlue className="icon-contact-info"/></span><span>Email</span></a>
                                                <Link href={whatsapp_link} className="text-xxs btn btn-light-blue" target="_blank"><span><WhatsAppIconBlue className="icon-contact-info" /></span><span className="d-none"> WhatsApp</span></Link>
                                          </div>
                      </div>
                    </div>
                  )
                }

              })
              }

            </Slider>


          </Col>
        </Row>

      </Container>
      <ModalTiny show={modalAskformOpen} onHide={closeAskformModal} className="modal-form-wrapper people-details-form-modal-main">
          <ModalTiny.Header>
          <div class="modal-close-btn" onClick={closeAskformModal}><CloseIcon /></div>
            <ModalTiny.Title><h4>Contact {negotiator?.Name}</h4></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body>
            <Form peopleName={negotiator?.Name} />
          </ModalTiny.Body>
        </ModalTiny>
      </section> 
  )
}

export default SimProperties