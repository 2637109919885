import React from "react"

export default function CurrConvertLayout(props) {

  let values = ['EUR','USD']
  if(typeof process.env?.GATSBY_CurrencyConverter !== undefined){
    values =  process.env.GATSBY_CurrencyConverter.split(',');
  }
  let currVal = values[0]
  const handleCurrencyConverter = event => {
    //set localstorage value
    if (typeof window !== 'undefined') {
      localStorage.setItem('currTo', event.target.value.trimStart());
      props.parentCallback(event.target.value.trimStart())
    }
  }

  if (typeof window !== 'undefined') {
    if(localStorage.getItem('currTo') != null){
      currVal = localStorage.getItem('currTo')
    }
  }

  return (
    <div className={props?.outterClass}>
      <select name="currencies" className={`${props?.selectClass} text-xs`} id="currencies" onChange={handleCurrencyConverter}>
          {values.map((val, i) => { 
            let myselect = '';
            if(currVal == val) { myselect = "selected"; }
            return (<option value={val} selected={myselect} key={i}>{val}</option>) })}
      </select>
  </div>
  )
}