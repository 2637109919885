import { Link } from "@StarberryUtils";
import React from "react"
import {Form,Nav,Tab} from "react-bootstrap"

import MortgageCalc from '../../calculators/mortgagecalc/';
import StampdutyCalc from '../../calculators/stampdutycalc/';
import RentalyieldCalc from '../../calculators/rentalyieldcalc';
import _ from "lodash"
import "./Calculator.scss";

const Calculator = (props) => {

  const getPOA = (data) => {
    var permit = "";
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }

  var prop_price = getPOA(props.extras) === "" ? props.price : "";
  console.log("Calc", props);
   return (
    <React.Fragment>
      <div className="calculator-block">
        <div className="mortgage-calculator-block calculator-item">
          <h6>Mortgage Calculator</h6>
          <MortgageCalc propertyPrice={prop_price} current={props.current}/>
        </div>
        <div className="Rental-calculator-block calculator-item">
          <h6>Rental Yield Calculator</h6>
          <RentalyieldCalc propertyValue={props.price}/>
        </div>
      </div>       
    </React.Fragment>
  )
}

export default Calculator