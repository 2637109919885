import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "@StarberryUtils";
import HTMLReactParser from 'html-react-parser';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { Container, Col, Row } from "react-bootstrap";
import "./PropertyAreaGuide.scss";

const PropertyAreaGuide = ({ area }) => {
    const data = useStaticQuery(graphql`
      query {
        glstrapi {
          areaGuides {
            id
            Title
            URL
            Header_Content
            Tile_Image {
              url
              alternativeText
            }
            imagetransforms
          }
        }
      }
    `);
  
    const areaGuide = data.glstrapi.areaGuides.find(
      (guide) => guide.Title === area
    );
      
    if (!areaGuide) {
      return null;
    }
     
    const image_url = areaGuide.Tile_Image.url;
    let processedImages = {};
    if (areaGuide?.imagetransforms?.Tile_Image_Transforms) {
      processedImages = areaGuide.imagetransforms.Tile_Image_Transforms;
    }
  
    return (
      <div className="property-areaguide-wrap">
        <Container className="px-0">
            <Row className="mx-0">
            <Col lg={4} className="px-0">
        <div className="property-areaguide-img">
            {image_url && (
            <div className="guide-image img-zoom">
                <Link to={`/living-in-dubai/${areaGuide.URL}`}>
                <ImageTransform
                    imagesources={image_url}
                    renderer="srcSet"
                    imagename="area-guides.Tile_Image.tile"
                    attr={{ alt: `${areaGuide.Title} - Dacha`, class: '' }}
                    imagetransformresult={processedImages}
                    id={areaGuide.id}
                />
                </Link>
            </div>
            )}
        </div>
        </Col>
        <Col lg={8} className="px-0">
        <div className="property-areaguide-content">
            {areaGuide.Title && <h6><Link to={`/living-in-dubai/${areaGuide.URL}`}>{areaGuide.Title} Community Guide</Link></h6>}

            {areaGuide.Header_Content && (
            <div className="guide-content text-xs">
                {HTMLReactParser(areaGuide.Header_Content.replace(/<br\s*\/?>/gi, ''))}
            </div>
            )}
            <div className="continue-link text-xs">
                <Link to={`/living-in-dubai/${areaGuide.URL}`}>Continue Reading</Link>
            </div>
        </div>        
        </Col></Row>
        </Container>
      </div>
    );
  };
  
  export default PropertyAreaGuide;